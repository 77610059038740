import React, { useState } from "react";
import { useEffect } from "react";
import "./status.css";
import * as statusMessage from "./statusmessages";

import { ScimResponse, GetBridgePath } from "../helpers/router";
import { useHistory } from "react-router";

import StatusReport from "./components/StatusReport";
import LogList from "./components/LogList";
import { useRedirect } from "../helpers/redirect";

interface Report {
  reports: ComponentReport[];
}

export interface ComponentReport {
  source: string;
  time: Date;
  expires: Date;
  state: string;
  remaining?: string;
}

interface ConnectionReport {
  connection: boolean;
  session: boolean;
  reason?: string;
}

const Status = (): JSX.Element => {
  const state = useRedirect();

  const history = useHistory();

  const [sessionToken, setToken] = useState(
    sessionStorage.getItem("session-token") || ""
  );

  const [deploymentHealth, setDeploymentHealth] = useState(
    statusMessage.unknown
  );
  const [deploymentText, setDeploymentText] = useState(
    statusMessage.genDeployment
  );

  const [connectionHealth, setConnectionHealth] = useState(
    statusMessage.unknown
  );
  const [connectionText, setConnectionText] = useState(
    statusMessage.genConnection
  );

  const [authenticationHealth, setAuthenticationHealth] = useState(
    statusMessage.unknown
  );
  const [authenticationText, setAuthenticationText] = useState(
    statusMessage.genAuth
  );

  const [scimHealth, setScimHealth] = useState(statusMessage.unknown);
  const [scimText, setScimText] = useState(statusMessage.genScim);

  const [provisionHealth, setProvisionHealth] = useState(statusMessage.unknown);
  const [provisionText, setProvisionText] = useState(
    statusMessage.genProvision
  );

  const [redisHealth, setRedisHealth] = useState(statusMessage.unknown);
  const [redisText, setRedisText] = useState(statusMessage.genRedis);
  const [logs, setLogs] = useState([]);

  const checkSessionToken = (): boolean => {
    if (sessionToken === "") {
      console.log("failed to get session token");

      setDeploymentHealth(statusMessage.unhealthy);
      setDeploymentText(statusMessage.unsuccessfulDeployment);
      setConnectionHealth(statusMessage.unhealthy);
      setConnectionText(statusMessage.error);
      setAuthenticationHealth(statusMessage.unhealthy);
      setAuthenticationText(statusMessage.error);
      setScimHealth(statusMessage.unhealthy);
      setScimText(statusMessage.error);
      setProvisionHealth(statusMessage.unhealthy);
      setProvisionText(statusMessage.error);
      setRedisHealth(statusMessage.unhealthy);
      setRedisText(statusMessage.error);

      return false;
    }
    setDeploymentHealth(statusMessage.healthy);
    setDeploymentText(statusMessage.successfulDeployment);
    return true;
  };

  function formatComponentReport(cr: ComponentReport): string {
    const time = cr.time === null ? Date.now() : cr.time;
    return `${cr.state} as of ${time}`;
  }

  const stateHealth = (report: Report): void => {
    report.reports.forEach((report: ComponentReport) => {
      if (report.source === "SCIMServer") {
        setScimHealth(report.state);
        setScimText(formatComponentReport(report));
      }

      if (report.source === "ProvisionWatcher") {
        setProvisionHealth(report.state);
        setProvisionText(formatComponentReport(report));
      }

      if (report.source === "RedisCache") {
        setRedisHealth(report.state);
        setRedisText(formatComponentReport(report));
      }
    });
  };

  const getHealthReport = async (): Promise<void> => {
    GetBridgePath("/status/health", sessionToken)
      .then((report) => {
        if (!report.ok) {
          throw report;
        }
        return report.json();
      })
      .then((json) => {
        stateHealth(json);
      })
      .catch((err) => {
        console.log("scim health error");
        if (err.status === 401) {
          history.push({
            pathname: "/app",
          });
        } else if (err.status === 500) {
          // this will be returned if b5 auth failed
          console.log("unable to retrieve report");
        }
      });
  };

  const stateStatus = (status: ConnectionReport): void => {
    switch (status.connection) {
      case true:
        setConnectionHealth(statusMessage.healthy);
        setConnectionText(statusMessage.successfulConnection);
        break;
      case false:
        setConnectionHealth(statusMessage.unhealthy);
        if (status.reason) {
          setConnectionText(status.reason);
        } else {
          setConnectionText(statusMessage.unsuccessfulConnection);
        }
        break;
    }

    switch (status.session) {
      case true:
        setAuthenticationHealth(statusMessage.healthy);
        setAuthenticationText(statusMessage.successfulAuth);
        break;
      case false:
        setAuthenticationHealth(statusMessage.unhealthy);
        setAuthenticationText(statusMessage.unsuccessfulAuth);
        break;
    }
  };

  //status prop
  const getStatusReport = async (): Promise<void> => {
    GetBridgePath("/status/info", sessionToken)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((json) => {
        console.log(json);
        stateStatus(json);
      })
      .catch((err) => {
        console.log("scim status error");

        setScimHealth(statusMessage.unhealthy);
        setScimText(statusMessage.error);
        setProvisionHealth(statusMessage.unhealthy);
        setProvisionText(statusMessage.error);
        setRedisHealth(statusMessage.unhealthy);
        setRedisText(statusMessage.error);

        if (err.status === 401) {
          history.push({
            pathname: "/app",
          });
        } else if (err.status === 403) {
          // for 403 errors like Firewall

          return err
            .json()
            .then((message: Response) => {
              return message;
            })
            .then((res: ScimResponse) => {
              stateStatus({
                connection: false,
                session: false,
                reason: res.detail,
              });
            });
        } else if (err.status === 500) {
          // this will be returned if b5 auth failed
          stateStatus({ connection: true, session: false });
        } else {
          stateStatus({ connection: false, session: false });
        }
        return;
      });
  };

  const generateLogList = async (): Promise<void> => {
    GetBridgePath("/status/logs", sessionToken)
      .then((logs) => {
        if (!logs.ok) {
          throw logs;
        }
        return logs.json();
      })
      .then((json) => {
        setLogs(json);
      })
      .catch((err) => {
        console.log("could not retrieve logs");
        setLogs([]);
        if (err.status === 401) {
          history.push({
            pathname: "/app",
          });
        }
      });
  };

  const logOut = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    console.log("clearing session token");

    // clears the session token from memory
    sessionStorage.removeItem("session-token");
    setToken("");

    history.push({
      pathname: "/app",
    });
  };

  useEffect(() => {
    console.log("getting reports");

    if (checkSessionToken()) {
      setTimeout(() => {
        getHealthReport();
        generateLogList();
        getStatusReport();
      }, 1000);
    }

    return;
    // Here we truly only want this to run once.
    // eslint-disable-next-line
  }, []);

  if (state.loading) {
    return <></>;
  }

  if (!state.sessionFileFound) {
    window.location.replace("/app/setup");
  }

  return (
    <div>
      <h2>1Password SCIM Bridge Status</h2>
      <StatusReport
        statusHealth={deploymentHealth}
        statusName={"Deployment"}
        statusText={deploymentText}
      />
      <StatusReport
        statusHealth={connectionHealth}
        statusName={"Connection"}
        statusText={connectionText}
      />
      <StatusReport
        statusHealth={authenticationHealth}
        statusName={"Authentication"}
        statusText={authenticationText}
      />
      <StatusReport
        statusHealth={scimHealth}
        statusName={"SCIM Server"}
        statusText={scimText}
      />
      <StatusReport
        statusHealth={provisionHealth}
        statusName={"Provision Watcher"}
        statusText={provisionText}
      />
      <StatusReport
        statusHealth={redisHealth}
        statusName={"Redis Cache"}
        statusText={redisText}
      />
      <LogList logs={logs} sessionToken={sessionToken} />

      <div id="logout" className="row">
        <form id="logout">
          <button
            type="submit"
            id="logout"
            onClick={(e) => logOut(e)}
            className="big-button"
          >
            Log out
          </button>
        </form>
      </div>
    </div>
  );
};

export default Status;
