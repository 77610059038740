import React, { useEffect, useState } from "react";
import "./setup.css";
import { GetBridgePathNoAuth } from "../helpers/router";
import { useHistory } from "react-router";
import healthy from "../img/healthy.svg";
import lock from "../img/1passwordlock.svg";
import { useRedirect } from "../helpers/redirect";

const Setup = (): JSX.Element => {
  const history = useHistory();

  const [domain, setDomain] = useState("");
  const [failedVerify, setFailedVerify] = useState("");
  const [configureDomain, setConfigureDomain] = useState("");
  const [connectVerified, setConnectVerified] = useState(false);
  const [dnsStatus, setDnsStatus] = useState("unknown");

  const state = useRedirect();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("verified") || window.location.protocol === "https:") {
      setFailedVerify("");

      const currentDomain =
        window.location.hostname + " configuration successful.";
      console.log(currentDomain);
      setDnsStatus("checked healthy");
      setConfigureDomain(currentDomain);
      setConnectVerified(true);
    }
  }, []);

  const buildVerifyRequest = (current: string, domain: string): string => {
    const verifyAddress = window.location.protocol + "//" + current + "/verify";
    return verifyAddress + "?domain=" + domain;
  };

  const getTLSCert = async (domain: string): Promise<void> => {
    const current = window.location.hostname;
    const url = buildVerifyRequest(current, domain);

    try {
      const resp = await fetch(url);
      if (resp.status > 299) {
        throw new Error("domain could not be verified");
      }
    } catch (err) {
      throw new Error("getTLSCert error: " + err);
    }
  };

  const getPath = async (path: string): Promise<string> => {
    try {
      const response = await GetBridgePathNoAuth(path + "/health");
      if (!response.ok) {
        throw new Error("Request failed: " + response.text);
      } else {
        return path;
      }
    } catch (err) {
      throw new Error("Failed to get '" + path + "': " + err);
    }
  };

  // getRoutablePath gets a valid URI that can be resolved.
  // Guarantees: if this function returns, it is the new valid path to redirect to. Otherwise an error is thrown.
  const getRoutablePath = async (
    oldPath: string,
    newPath: string,
    retries: number
  ): Promise<string> => {
    try {
      if (oldPath === "http://localhost") {
        return await Promise.resolve(getPath(newPath));
      }
      const path = await Promise.any([getPath(oldPath), getPath(newPath)]);
      return path;
    } catch (err) {
      if (retries === 0) {
        throw new Error("Out of retries: " + err);
      }
      console.log("1Password SCIM Bridge TLS upgrade not ready. Retrying...");

      // pause between tries
      await new Promise((resolve) => setTimeout(resolve, 1000));

      return getRoutablePath(oldPath, newPath, retries - 1);
    }
  };

  const testDomain = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const regexp = /(^\w+:|^)\/\/|(\/)/g;

    const newDomain = domain.replace(regexp, "");

    if (newDomain === "localhost" || newDomain === "127.0.0.1") {
      history.push("/app/setup?verified=true");
      setConnectVerified(true);
      setDnsStatus("checked healthy");
      return;
    }

    const oldPath = "http://" + window.location.hostname;
    const newPath = "https://" + newDomain;

    try {
      await getTLSCert(newDomain);
      setFailedVerify("");

      setConfigureDomain("Verifying " + window.location.hostname + "...");
    } catch (err) {
      console.log("getTLSCert error: " + err);
      setFailedVerify(
        "Couldn't verify domain. Check your configuration and try again. Ensure the DNS record has had time to propagate, and that port 443 is open on your firewall."
      );
      return;
    }

    try {
      const path = await getRoutablePath(oldPath, newPath, 35);
      console.log("PATH", path, newPath);
      if (path === newPath) {
        window.location.replace(path);
      } else {
        setFailedVerify(
          "The 1Password SCIM bridge isn’t responding. Make sure the bridge is still working and available on the network."
        );
        setConfigureDomain("");
      }
    } catch (err) {
      console.log("Failed to resolve TLS upgrade: " + err);
      setFailedVerify(
        "LetsEncrypt challenge attempt failed. Verify that your SCIM bridge's domain name has not been rate limited."
      );
      setConfigureDomain("");
    }
  };

  if (state.loading) {
    return <></>;
  }

  if (state.sessionFileFound) {
    window.location.replace("/app/login");
  }

  return (
    <div>
      <h2>1Password SCIM Bridge Setup</h2>
      <div className="row deployment-status">
        <section className="status">
          <img
            alt="healthy"
            src={healthy}
            className="healthy checked"
            id="deployment-checkmark"
          />
        </section>
        <section className="description">
          <h2>Deploy</h2>
          <p>Deployment successful.</p>
        </section>
      </div>
      <div className="row dns-status">
        <section className="status">
          <img
            alt="healthy"
            src={healthy}
            className={dnsStatus}
            id="dns-status-healthy"
          />
        </section>
        <section className="description">
          <h2>Configure domain</h2>
          {!configureDomain && (
            <div id="configure-domain">
              <p id="dns-description">
                Configure a DNS record for your SCIM bridge and enter the domain
                name:
              </p>
              <form onSubmit={testDomain} id="domain-checker-form">
                <input
                  type="text"
                  id="letsencrypt-domain-field"
                  value={domain}
                  onChange={(e) => setDomain(e.currentTarget.value)}
                  placeholder={window.location.hostname}
                />
                <button type="submit" className="verify">
                  Verify
                </button>
              </form>
              <p id="letsencrypt-info">
                Your SCIM bridge domain will be issued a TLS certificate using
                the{" "}
                <a href="https://letsencrypt.org/" target="blank">
                  Let's Encrypt
                </a>{" "}
                service.
              </p>
            </div>
          )}
          {configureDomain && (
            <p id="configure-domain-verified">{configureDomain}</p>
          )}
          {failedVerify && (
            <p id="failed-verification" className="errormsg">
              {failedVerify}
            </p>
          )}
        </section>
      </div>
      <div className="row account-configure">
        <section className="status">
          <img
            alt="healthy"
            src={healthy}
            className="healthy"
            id="account-configure-status"
          />
        </section>
        <section className="description">
          <h2>Connect account</h2>
          {connectVerified && (
            <div>
              {" "}
              <p id="connect-description">Sign in to connect with 1Password:</p>
              <button
                className="onepassword-redirect"
                onClick={() => window.location.replace("/signinredirect")}
              >
                <img alt="lock" src={lock} className="tinyimg" />
                Sign in with 1Password
              </button>{" "}
            </div>
          )}
          {!connectVerified && (
            <p id="waiting-verification">Domain configuration required.</p>
          )}
        </section>
      </div>
    </div>
  );
};

export default Setup;
